import { MaskedRange } from 'imask'
import { ref, computed, reactive } from 'vue'
import { jwt } from '/~/core/api'
import bottomSheet from '/~/core/bottom-sheet'
import emitter from '/~/core/emitter'
import modal from '/~/core/mdl'
import { createDate, formatDate } from '/~/utils/format/date'
import { useBackendValidation } from '/~/composables/backend-validation'
import { useForm } from '/~/composables/base/use-form'
import { useCms } from '/~/composables/cms/use-cms'
import { useLocalization } from '/~/composables/localization'
import { useProvider } from '/~/composables/provider'
import { useUser } from '/~/composables/user'

interface EditProfileComponentProps {
  mobile?: boolean
  to?: string
  isFormFieldsScrollable?: boolean
  showOnlyRequiredFields?: boolean
  id?: string | null
}

type FieldConfig = {
  name: string
  placeholder?: string
  label: string
  visible: boolean
  required: boolean
  disabled: boolean
  passwordRequired: boolean
  isVisible?: boolean
  isRequired?: boolean
  isEditable?: boolean
  isPasswordRequired?: boolean
  backEndName?: string
  mask?: any
  validate?: string
  type?: string
  disclaimer?: any
  textarea?: boolean
}

export function useEditProfileComponent(
  props: EditProfileComponentProps,
  emit: (event: 'profile-saved') => void
) {
  const localization = useLocalization()
  const { editProfileEmailDisclaimer } = useCms()
  const {
    userMetadataMarketingCommsEmail,
    profileConfigForm,
    isProfileEditable,
  } = useProvider()
  const { user, updating, updateUserDetails, marketingCommsEmail } = useUser()
  const { backendErrors, processBackendErrors } = useBackendValidation()
  const { validationObserverRef } = useForm()
  const form = reactive({
    firstName: user.value.firstName,
    lastName: user.value.lastName,
    email: user.value.hasTemporaryEmail ? '' : user.value.email,
    mobile: user.value.mobile,
    username: user.value.username,
    birthDate: getDateString(user.value.birthDate),
    startDate: getDateString(user.value.startDate),
    sex: user.value.sex,
    bio: user.value.bio,
    marketing_comms_email: marketingCommsEmail.value,
  })

  function getDateString(date: any) {
    return date ? formatDate('daymonthyearnumeric', createDate(date)) : ''
  }

  const genderFields = ref([
    { label: 'Male', value: 1 },
    { label: 'Female', value: 2 },
  ])

  const confirmedPassword = ref<string>()

  const disabled = computed(() => updating.value || !isProfileEditable.value)

  const formFields = computed(() => {
    const {
      firstName,
      lastName,
      email,
      username,
      mobile,
      bio,
      dob: birthDate,
      startDate,
    } = profileConfigForm.value

    const fields: FieldConfig[] = [
      {
        name: 'firstName',
        backEndName: 'first_name',
        ...getField(firstName, 'First Name'),
      },
      {
        name: 'lastName',
        backEndName: 'last_name',
        ...getField(lastName, 'Last Name'),
      },
      {
        name: 'startDate',
        backEndName: 'start_date',
        ...getField(startDate, 'Start Date'),
        placeholder: localization.getDatePickerFormat('daymonthyearnumeric'),
        mask: {
          mask: localization.getDatePickerFormat('daymonthyearnumeric'),
          lazy: true,
          blocks: {
            YYYY: {
              mask: MaskedRange,
              from: 1910,
              to: new Date().getFullYear(),
            },
            MM: {
              mask: MaskedRange,
              from: 1,
              to: 12,
            },
            DD: {
              mask: MaskedRange,
              from: 1,
              to: 31,
            },
          },
        },
      },
      {
        name: 'marketing_comms_email',
        label:
          userMetadataMarketingCommsEmail.value?.label ?? 'Marketing Email',
        visible: Boolean(userMetadataMarketingCommsEmail.value),
        required: true,
        disabled: true,
        passwordRequired: true,
        validation: { rules: 'email' },
        type: 'email',
      },
      {
        name: 'email',
        ...getField(email, 'Email'),
        validation: { rules: 'email' },
        type: 'email',
        disclaimer: editProfileEmailDisclaimer.value,
      },
      {
        name: 'username',
        ...getField(username, 'User Name'),
      },
      {
        name: 'mobile',
        ...getField(mobile, 'Mobile'),
        validation: { rules: 'mobile' },
        mask: 'mobile',
        type: 'tel',
      },
      {
        name: 'birthDate',
        backEndName: 'dob',
        ...getField(birthDate, 'Date of Birth'),
        placeholder: localization.getDatePickerFormat('daymonthyearnumeric'),
        mask: {
          mask: localization.getDatePickerFormat('daymonthyearnumeric'),
          blocks: {
            YYYY: {
              mask: MaskedRange,
              from: 1910,
              to: new Date().getFullYear(),
            },
            MM: {
              mask: MaskedRange,
              from: 1,
              to: 12,
            },
            DD: {
              mask: MaskedRange,
              from: 1,
              to: 31,
            },
          },
        },
      },
      {
        name: 'bio',
        ...getField(bio, 'Bio'),
        textarea: true,
      },
    ]

    return fields
      .filter((field) => field.visible)
      .map((field) => ({
        ...field,
        label: toSentenceCase(field.label),
        disabled: field.disabled || disabled.value,
        validation: {
          rules: [field.required ? 'required' : '', field.validation?.rules]
            .filter((i) => i)
            .join('|'),
        },
        error: backendErrors.value[field.backEndName || field.name],
      }))
  })

  const visibleFormFields = computed(() =>
    props.showOnlyRequiredFields
      ? formFields.value.filter((field) => field.required)
      : formFields.value
  )

  const genderField = computed(() => {
    const { sex } = profileConfigForm.value
    const field: FieldConfig = {
      name: 'sex',
      ...getField(sex, 'Gender'),
    }

    return {
      ...field,
      disabled: field.disabled || disabled.value,
      validation: {
        rules: [field.required ? 'required' : '', field.validation?.rules]
          .filter((i) => i)
          .join('|'),
        vid: 'sex',
        name: 'Gender',
        mode: 'aggressive',
      },
      error: (backendErrors as any)[field.backEndName || field.name],
    }
  })

  function getField(
    config: {
      label?: string
      isVisible?: boolean
      isRequired?: boolean
      isEditable?: boolean
      isPasswordRequired?: boolean
    } = {},
    defaultTitle: string
  ) {
    const {
      label = defaultTitle,
      isVisible = false,
      isRequired = false,
      isEditable = false,
      isPasswordRequired = false,
    } = config

    return {
      label,
      visible: isVisible,
      required: isRequired,
      disabled: !isEditable,
      passwordRequired: isPasswordRequired,
    }
  }

  function toSentenceCase(text: string) {
    return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase()
  }

  async function onSubmitForm() {
    if (disabled.value) {
      return
    }

    backendErrors.value = {}
    emitter.emit('edit-profile-errors', backendErrors.value)

    const fields: FieldConfig[] = [...formFields.value, genderField.value]
    const payload: any = {}

    for (const field of fields) {
      if (!field.disabled && field.visible && (form as any)[field.name]) {
        payload[field.backEndName || field.name] = (form as any)[field.name]
      } else {
        delete fields[field as any]
      }
    }

    payload.currentPassword = confirmedPassword.value

    if (
      fields.some(
        (field) =>
          field.passwordRequired &&
          !field.disabled &&
          validationObserverRef.value?.fields?.[field.label]?.changed
      ) &&
      !payload.currentPassword
    ) {
      if (props.mobile) {
        bottomSheet.show('profile-password-confirmation', {
          to: props.to,
          on: {
            submit: (value: string) => {
              confirmedPassword.value = value
              onSubmitForm()
            },
          },
        })
      } else {
        modal.show('profile-password-confirmation', {
          on: {
            submit: (value: string) => {
              confirmedPassword.value = value
              onSubmitForm()
            },
          },
        })
      }

      return
    }

    confirmedPassword.value = undefined

    try {
      const oldEmail = user.value.email
      const newUser = await updateUserDetails(payload)

      if (oldEmail !== payload.email && eonx.config.login.type === 'email') {
        await jwt.refetch()
      }

      emit('profile-saved')

      return newUser
    } catch (error) {
      processEmitBackendErrors(error)
    } finally {
      if (!backendErrors.value.currentPassword) {
        if (props.mobile) {
          bottomSheet.hide('profile-password-confirmation')
        } else {
          modal.hide('profile-password-confirmation')
        }
      }
    }
  }

  function processEmitBackendErrors(items: any) {
    processBackendErrors({ errors: items } as any)
    emitter.emit('edit-profile-errors', backendErrors.value)
  }

  const isFormValid = computed(
    () => !visibleFormFields.value.find((field) => field.error)
  )

  const errorsList = computed(() =>
    visibleFormFields.value
      .map((item) => item.error)
      .filter((item) => item)
      .join('. ')
  )

  return {
    form,
    formFields,
    visibleFormFields,
    genderFields,
    genderField,
    updating,
    onSubmitForm,
    disabled,
    isFormValid,
    errorsList,
    validationObserverRef,
  }
}
